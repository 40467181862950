<template>
  <Layout :tituloPagina="`Calendario | Ajustes | ${modo=='nuevo' ? 'Nuevo' : (modo == 'edicion' ? 'Edición de' : 'Visor de')} calendario`">
    <div class="row">
      <div class="col-lg-6 col-md-7 col-sm-8 col-12">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{ modo == 'nuevo' ? 'Nuevo calendario' : '' }}
              {{ modo == 'edicion' ? 'Edición de calendario' : '' }}
              {{ modo == 'visor' ? 'Visor de calendario' : '' }}
            </h4>
            <div>
              <div class="dropdown d-inline-block">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li v-if="modo != 'visor'">
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="atras()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label>Nombre</label>
                <input
                  class="form-control"
                  placeholder="Nombre del calendario"
                  ref="nombre"
                  name="nombre"
                  v-model="calendario.nombre"
                  :readonly="calendario.generico"
                />
              </div>
              <div class="col-md-3 col-6">
                <label>Visible por defecto</label>
                <div class="form-check form-check-success form-switch form-switch-lg">
                  <input
                    :checked="calendario.visible"
                    @change="calendario.visible = !calendario.visible"
                    class="form-check-input"
                    type="checkbox"
                    :disabled="calendario.generico"
                    id="visible"
                  />
                  <label
                    class="form-check-label"
                    for="visible"
                  ></label>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <label>Color por defecto</label>
                <input
                  type="color"
                  class="form-control form-control-color"
                  placeholder="Nombre del producto"
                  ref="color"
                  name="color"
                  :readonly="calendario.generico"
                  :disabled="calendario.generico"
                  v-model="calendario.color"
                />
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-3">
                <div class="form-check form-check-success">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="mode"
                    id="para-todos"
                    :checked="calendario.todos"
                    @change="calendario.todos = !calendario.todos"
                    :readonly="calendario.generico"
                    :disabled="calendario.generico"
                  />
                  <label
                    class="form-check-label"
                    for="para-todos"
                  >
                    Para todos
                  </label>
                </div>
              </div>
              <div class="col-md-9">
                <p v-show="!calendario.todos">
                  <i class="mdi mdi-arrow-left-thick"></i>
                  Si activas esta opción, el calendario será
                  visible para todos los usuarios y roles.
                </p>
              </div>
            </div>
            <div class="row" v-if="!calendario.todos">
              <div class="col-md-6">
                <label for="">Usuarios</label>
                <multiselect
                  v-model="calendario.ids_usuarios"
                  placeholder="Buscar Usuario"
                  selectLabel="Presione enter para seleccionar"
                  selectedLabel="Seleccionado"
                  deselectLabel="Presione enter para eliminar"
                  label="nombre"
                  track-by="id"
                  :options="usuarios"
                  :multiple="true"
                  :taggable="false"
                ></multiselect>
              </div>
              <div class="col-md-6">
                <label for="">Roles</label>
                <multiselect
                  v-model="calendario.ids_roles"
                  placeholder="Buscar ROL"
                  selectLabel="Presione enter para seleccionar"
                  selectedLabel="Seleccionado"
                  deselectLabel="Presione enter para eliminar"
                  label="nombre"
                  track-by="id"
                  :options="roles"
                  :multiple="true"
                  :taggable="false"
                ></multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <div class="btn-group">
            <button @click="atras()" class="btn btn-secondary">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
            <button
              class="btn btn-success"
              @click="actualizar()"
              v-if="modo == 'edicion'"
              :disabled="bandera_spinner"
            >
              <i 
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              Actualizar
            </button>
            <button
              class="btn btn-success"
              @click="guardar()"
              v-if="modo == 'nuevo'"
              :disabled="bandera_spinner"
            >
              <i 
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import CalendarioSrv from "@/services/CalendarioSrv.js"
import UsuarioSrv from '@/services/UsuarioSrv.js'
import RolUsuariosSrv from '@/services/RolUsuariosSrv.js'
import Multiselect from 'vue-multiselect'
import Layout from '@/views/layouts/main'

export default {
  name: 'EdicionAjusteCalendario',
  components: { 
    Layout,
    Multiselect
  },
  data() {
    return {
      modo: 'nuevo',
      bandera_spinner: false,
      id_calendario: null,

      calendario: {
        id: null,
        nombre: '',
        visible: false,
        color: '#FFFFFF',
        todos: false,
        ids_usuarios: [],
        ids_roles: [],
      },

      usuarios: [],
      roles: []
    }
  },

  created: function() {
    var self = this

    self.id_calendario = self.$route.params.id
    // Determinar el modo de la vista
    if (self.$route.path.indexOf('nuevo') != -1) self.modo = 'nuevo'
    if (self.$route.path.indexOf('edicion') != -1) self.modo = 'edicion'
    if (self.$route.path.indexOf('visor') != -1) self.modo = 'visor'

    self.cargarUsuarios()
    self.cargarRoles()

    switch(self.modo){
      case 'nuevo':
        self.calendario.ids_usuarios.push({
          id: self.usuario.id,
          nombre: self.usuario.nombre+' '+(self.usuario.apellido_paterno || '')+' '+(self.usuario.apellido_materno || '')
        })
      break;
      case 'edicion':
        self.cargarCalendario()
      break;
      case 'visor':
        self.cargarCalendario()
      break;
    }
  },

  computed: {
    usuario() {
      return this.$store.state.todo.usuario
    }
  },

  methods: {
    actualizar: function() {
      var self = this

      self.bandera_spinner = true

      if (self.calendario.nombre == null || self.calendario.nombre == '') {
        iu.msg.warning('Es necesario ingresar un nombre al calendario')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      let total_ids = self.calendario.ids_roles.length + self.calendario.ids_usuarios.length

      if( !self.calendario.todos && total_ids == 0 ) {
        iu.msg.warning('Es necesario registrar al menos un "Usuario o Rol"')
        self.bandera_spinner = false
        return
      }

      CalendarioSrv.actualizar(self.calendario).then(response => {
        iu.msg.success('Se actualizo correctamente el calendario con nombre: '+ self.calendario.nombre)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar el calendario'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    atras: function() {
      this.$router.go(-1)
    },

    cargarCalendario: function() {
      var self = this, params = {
        con_ids_usuarios: true,
        con_ids_roles: true
      }

      CalendarioSrv.calendarioJSON(self.id_calendario, params).then(response => {
        self.calendario = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el calendario'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarUsuarios: function() {
      var self = this

      let params = { todos: true }

      UsuarioSrv.usuariosJSON(params).then(response => {
        let usuarios = response.data

        self.usuarios = []
        usuarios.forEach(usu => {
          let datos = {
            id: usu.id,
            nombre: usu.nombre+' '+(usu.apellido_paterno || '')+' '+(usu.apellido_materno || '')
          }
          self.usuarios.push(datos)
        });
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los usuarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarRoles: function() {
      let self = this

      RolUsuariosSrv.rolesJSON().then(response => {
        let roles = response.data

        self.roles = []
        roles.forEach(rol => {
          let datos = {
            id:rol.id,
            nombre: rol.nombre
          }
          self.roles.push(datos)
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los roles'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    guardar: function() {
      var self = this

      self.bandera_spinner = true

      if (self.calendario.nombre == null || self.calendario.nombre == '') {
        iu.msg.warning('Es necesario ingresar un nombre al calendario')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      let total_ids = self.calendario.ids_roles.length + self.calendario.ids_usuarios.length

      if( !self.calendario.todos && total_ids == 0 ) {
        iu.msg.warning('Es necesario registrar al menos un "Usuario o Rol"')
        self.bandera_spinner = false
        return
      }

      CalendarioSrv.guardar(self.calendario).then(response => {
        iu.msg.success('Se guardó correctamente el nuevo calendario con nombre: '+ self.calendario.nombre)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar el calendario'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    }
  }
}
</script>

<style scoped>
</style>