import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/calendarios`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(calendario) {
    return apiClient.post(`${calendario.id}`, calendario)
  },

  actualizarAjustes(idCalendario, datos) {
    return apiClient.post(`${idCalendario}/ajustes-calendario`, datos)
  },  

  eliminar(idCalendario) {
    return apiClient.delete(`${idCalendario}`)
  },

  eliminarAjustes(idCalendario, idUsuario) {
    return apiClient.delete(`${idCalendario}/ajustes/${idUsuario}`)
  },

  guardar(calendario) {
    return apiClient.post('', calendario)
  },

  calendarioJSON(idCalendario, params) {
    params = params || {}
    return apiClient.get(`${idCalendario}.json`, { params: params })
  },

  calendariosJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/calendarios.json`, { params: params })
  }
}